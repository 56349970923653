// UtilisateursPage.js
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";

// Composant de formulaire pour l'ajout d'utilisateur
function AjouterUtilisateurForm() {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const loginRef = useRef();
  const passwordRef = useRef();
  const password_confirmationRef = useRef();
  const profilRef = useRef();
  const ind_revoque = 0;

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    const utilisateurs = {
      login: loginRef.current.value,
      password: passwordRef.current.value,
      password_confirmation: password_confirmationRef.current.value,
      profil: profilRef.current.value,
      ind_revoque: ind_revoque,
    };

    // requête pour ajouter un utilisateurs
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/add_utilisateur`,
        utilisateurs
      )
      .then((response) => {
        document.getElementById("monFormulaire").reset();
        setError(null);
        setSuccess(response.data.msg);
      })
      .catch((error) => {
        setSuccess(null);
        setError(error.response.data.status);
        console.log(error.response.data.status);
      });
  };

  return (
    //Formulaire pour ajouter un Utilisateurs
    <>
      <div className="container">
        <div className="container-form">
          <h2 className="title">Ajouter un Utilisateur</h2>
          <form onSubmit={handleSubmit} id="monFormulaire">
            <div>
              <div className="block-label">
                <span className="obligatoire">*</span>
                <label htmlFor="login" className="block-label">
                  Login :
                </label>
                <span className="error">
                  {error !== 400 ? "" : <p>Login inconnu !</p>}
                </span>
                <span className="error">
                  {error !== 422 ? "" : <p>Cet utilisateur exist déjà !</p>}
                </span>
              </div>
              <div className="block-form">
                <input
                  type="email"
                  className="input-form"
                  id="login"
                  ref={loginRef}
                  placeholder="Entrez l'adresse email..."
                  required
                />
              </div>
            </div>
            <div>
              <div className="block-label">
                <span className="obligatoire">*</span>
                <label htmlFor="login" className="block-label">
                  Entrez le mot de passe :
                </label>
                <span className="error">
                  {error !== 401 ? "" : <p>Mot de passe invalid !</p>}
                </span>
              </div>
              <div className="block-form">
                <input
                  type="password"
                  className="input-form"
                  id="password"
                  ref={passwordRef}
                  placeholder="Entrez votre mot de passe"
                  required
                />
              </div>
            </div>
            <div>
              <div className="block-label">
                <span className="obligatoire">*</span>
                <label htmlFor="login" className="block-label">
                  Confirmez mot de passe :
                </label>
                <span className="error">
                  {error !== 401 ? "" : <p>Mot de passe invalid !</p>}
                </span>
              </div>
              <div className="block-form">
                <input
                  type="password"
                  className="input-form"
                  id="password_confirmation"
                  ref={password_confirmationRef}
                  placeholder="Confirmez le mot de passe"
                  required
                />
              </div>
            </div>

            <div>
              <div className="block-label">
                <span className="obligatoire">*</span>
                <label htmlFor="login" className="block-label">
                  Entrez le profil :
                </label>
                <span className="error">
                  {error !== 403 ? "" : <p>Profil inconnu !</p>}
                </span>
                <div className="block-form">
                  <select
                    className="input-form"
                    id="profil"
                    ref={profilRef}
                    required
                  >
                    <option value="">Sélectionner le profil</option>
                    <option value="ADM">Administrateur</option>
                    <option value="USR">Utilisateur</option>
                  </select>
                </div>
              </div>
            </div>
            <button type="submit" className="btn revoque">
              Ajouter
            </button>
            {!success ? "" : <button className="success">{success}</button>}
          </form>
        </div>
      </div>
      <div className="mt-30 text-center">@ProjetAdidy</div>
    </>
  );
}

// Composant de formulaire pour la modification d'utilisateur
function ModifierUtilisateurForm({ selectedUserId }) {
  return (
    <>
      <p className="mt-2">
        Loading Parametrage Modifier Utilisateurs page by Id = {selectedUserId}
      </p>
    </>
  );
}

// Composant de formulaire pour la modification d'utilisateur
function ModifierPasswordForm({ selectedUserId, selectedUserLogin }) {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const passwordRef = useRef();
  const password_confirmationRef = useRef();

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    const utilisateurs = {
      password: passwordRef.current.value,
      password_confirmation: password_confirmationRef.current.value,
    };

    // requête pour ajouter un utilisateurs
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/change_password/${selectedUserId}`,
        utilisateurs
      )
      .then((data) => {
        document.getElementById("monFormulaire").reset();
        setError(null);
        setSuccess(data.data.msg);
      })
      .catch((error) => {
        setSuccess(null);
        setError(error);
      });
  };

  return (
    //Formulaire pour Modifier Mot de passe
    <>
      <div className="container">
        <div className="container-form">
          <h2 className="title">Modifier le mot de passe</h2>
          <form onSubmit={handleSubmit} id="monFormulaire">
            <div>
              <div className="block-label">
                <label htmlFor="login" className="block-label">
                  Login du matricule <b className="ml-5">{selectedUserId}</b> :
                </label>
              </div>
              <div className="block-form">
                <input
                  type="email"
                  className="input-form"
                  readOnly
                  id="login"
                  value={selectedUserLogin}
                />
              </div>
            </div>
            <div>
              <div className="block-label">
                <span className="obligatoire">*</span>
                <label htmlFor="password" className="block-label">
                  Nouveau mot de passe :
                </label>
                <span className="error">
                  {!error ? "" : <p>Mot de passe invalid !</p>}
                </span>
              </div>
              <div className="block-form">
                <input
                  type="password"
                  className="input-form"
                  id="password"
                  ref={passwordRef}
                  placeholder="Entrez votre mot de passe"
                  required
                />
              </div>
            </div>
            <div>
              <div className="block-label">
                <span className="obligatoire">*</span>
                <label htmlFor="login" className="block-label">
                  Confirmez mot de passe :
                </label>
                <span className="error">
                  {!error ? "" : <p>Mot de passe invalid !</p>}
                </span>
              </div>
              <div className="block-form">
                <input
                  type="password"
                  className="input-form"
                  id="password_confirmation"
                  ref={password_confirmationRef}
                  placeholder="Confirmez le mot de passe"
                  required
                />
              </div>
            </div>
            <button type="submit" className="btn revoque">
              Valider
            </button>
            <div className="block-form">
              {!success ? (
                ""
              ) : (
                <div className="mt-30 text-success">{success}</div>
              )}
            </div>
          </form>
        </div>
      </div>
      <div className="mt-30 text-center">@ProjetAdidy</div>
    </>
  );
}

// Composant de formulaire pour la révocation d'utilisateur
function RevoquerUtilisateurForm({ selectedUserId }) {
  return <p className="mt-2">Révoquer action by Id = {selectedUserId}</p>;
}

// Composant de formulaire pour la révocation d'utilisateur
function SelectIdForm({ selectedUserId }) {
  return <h4 className="mt-20 text-center title">Matricule = {selectedUserId}</h4>;
}

function UtilisateursPage({ users }) {
  const [action, setAction] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectUserLogin, setSelectUserLogin] = useState(null);

  // Gestionnaire d'événements pour la modification de l'utilisateur sélectionné
  function handleInputChange(event) {
    const userId = event.target.value;
    setSelectedUserId(userId);
    setAction('select');
  }

  const selectLogin = async () => {
    // Envoyer une requête POST à l'endpoint de déconnexion
    if (selectedUserId) {
      await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/show_one_utilisateur/${selectedUserId}`
        )
        .then((response) => {
          const userSelected = response.data.utilisateurs.login;
          setSelectUserLogin(userSelected);
        })
        .catch((error) => {
          console.error(
            "Une erreur s'est produite lors du chargement des données:",
            error
          );
        });
    }
  };

  useEffect(() => {
    selectLogin();
  }, []);

  const renderForm = () => {
    switch (action) {
      case "ajouter":
        return <AjouterUtilisateurForm />;
      case "modifier":
        return <ModifierUtilisateurForm selectedUserId={selectedUserId} />;
      case "revoquer":
        return <RevoquerUtilisateurForm selectedUserId={selectedUserId} />;
        case "select":
        return <SelectIdForm selectedUserId={selectedUserId} />;
      case "modifierpassword":
        return (
          <ModifierPasswordForm
            selectedUserId={selectedUserId}
            selectedUserLogin={selectUserLogin}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="block-liste">
        <div className="liste-title">
          <p>LISTE DES UTILISATEURS</p>
        </div>
        <div className="liste-containt">
          <div className="table">
            <div className="theader">
              <div className="check">
                <div className="box"></div>
              </div>
              <div className="w-100">N° :</div>
              <div className="w-300 pl-10">Email</div>
              <div className="w-200">Profil</div>
              <div className="w-200">Indice Revoque</div>
              <div className="w-350">Date de Création</div>
            </div>
            <div className="tbody">
              {users.map((user, index) => (
                <div key={user.id} className="liste-value">
                  <div>
                    <input
                      className="check check-box"
                      type="radio"
                      name="selectedUser"
                      value={user.id}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </div>
                  <div className="w-100">{index + 1}</div>
                  <div className="w-300">{user.login}</div>
                  <div className="w-200">
                    {user.profil === "ADM" ? "Administrateur" : "Utilisateur"}
                  </div>
                  <div className="w-200">
                    {user.ind_revoque === "0" ? "Non Révoqué" : "Revoqué"}
                  </div>
                  <div className="w-350">{user.created_at}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="button-modif">
        <button className="btn-param" onClick={() => setAction("ajouter")}>
          Ajouter
        </button>
        <button className="btn-param" onClick={() => setAction("modifier")}>
          Modifier utilisateur
        </button>
        <button
          className="btn-param"
          onClick={() => {
            setAction("modifierpassword");
            selectLogin();
          }}
        >
          Modifier password
        </button>
        <button
          className="btn-param revoque"
          onClick={() => setAction("revoquer")}
        >
          Révoquer
        </button>
      </div>
      <div className="bar"></div>
      <div className="mt-30">{renderForm()}</div>
    </>
  );
}

export default UtilisateursPage;
