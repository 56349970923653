//ContextProviders js
import {createContext, useContext, useState } from "react";

const stateContext = createContext({
    user: null,
    token: null,
    setUser: ()=>{},
    setToken: ()=>{}
})

//Mémoriser l'utilisateur connecter avec le token, accès token access_token
export const ContextProvider = ({children}) => {

    const [user,setUser] = useState(null);
    // const [token,_setToken] = useState('');
    const [token,_setToken] = useState(localStorage.getItem("ACCESS_TOKEN"));

    const setToken = (token) => {
        _setToken(token)
        if(token) {
            localStorage.setItem("ACCESS_TOKEN",token)
        }else {
            localStorage.removeItem("ACCESS_TOKEN")
        }
    }


//utiliser les variable par le token access_token
    return (
    <stateContext.Provider value={{
        user,
        token,
        setUser,
        setToken
    }} >
        {children}
    </stateContext.Provider>)
}

export const useStateContext = () =>  useContext(stateContext)