// axiosConfig.js

import axios from 'axios';


const instance = axios.create({
  baseURL: 'https://adidyback.ohsimpleworld.com/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Configuration globale pour Axios pour inclure les en-têtes CORS
instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export default instance;
