// ReferencesPage.js
import React from 'react';

function ReferencesPage() {
  return (
    <>
      <h2>Liste des Références</h2>
      <p className="mt-2">Lorem ipsum dolor sit amet consectetur adipisicing elit...</p>
    </>
  );
}

export default ReferencesPage;
