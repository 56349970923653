//GuestLayout js
//pages pour connexion, mot de passe oublié

import React, {useEffect} from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useStateContext } from "../Context/ContextProvider";


export default function GuestLayout() {
    const navigate = useNavigate();
    const {token } = useStateContext();


//redirection vers la pages d'accueil si l'utilisateur est connecté
    useEffect(() => {
        if (token) {
            navigate('/accueil');
        }
    }, [token, navigate]);

    // Rendu du composant
    return (
        <div>
            <Outlet />
        </div>
    );
}
