import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { format } from "date-fns";

function MembreAdminPage() {
  const [users, setMembre] = useState([]);
  const [usersId, setMembreId] = useState(null);
  const [usersect, setMembreSect] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [dateselectedUser, setSelecteddateUser] = useState([]);
  const [action, setAction] = useState(null);
  const [genreUser, setGenre] = useState("");
  const [indiceUser, setIndice] = useState("");
  const [activeInput, setActiveInput] = useState("");
  const [loading, setLoading] = useState(true);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [searchName, setSearchName] = useState("");
  const [searchFirstName, setSearchFirstName] = useState("");
  const [searchPerformed, setSearchPerformed] = useState(false);

  //Admin role, récuperation de tous les utilisateurs
  const show_membre = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/show_membre`)
      .then((response) => {
        setMembre(response.data.membres);
        setLoading(false);
      })
      .catch((error) => {
        console.error(
          "Une erreur s'est produite lors du chargement des données:",
          error
        );
      });
  };
  useEffect(() => {
    show_membre();
  }, []);

  //Admin role, récuperation utilisateurs section
  const show_membre_section = async (usersId) => {
    if (usersId) {
      await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/show_membre_section/${usersId}`
        )
        .then((response) => {
          if (response.data.membres_section.length === 0) {
            console.log("Secteur:", "Pas de section");
          } else {
            setMembreSect(response.data.membres_section);
          }
        })
        .catch((error) => {
          console.error(
            "Une erreur s'est produite lors du chargement des données:",
            error
          );
        });
    }
  };

  useEffect(() => {
    show_membre_section();
  }, []);

  if (loading) {
    return (
      <>
        <div className="loading">
          <div className="loader-wrapper">
            <div className="loader"></div>
          </div>
        </div>
      </>
    );
  }

  // Gestionnaire d'événements pour la modification de le membre sélectionné
  function handleInputChange(event) {
    const userId = event.target.value;

    setMembreId(userId);

    setAction("checked");

    setMembreSect([]);

    setActiveInput("active");

    show_membre_section(userId);

    // Recherche de l'membre par son ID
    const selectedUser = users.find((user) => {
      return user.id === parseInt(userId);
    });

    // Vérifiez si membre sélectionné exist dans l'users
    if (selectedUser) {
      setSelectedUser(selectedUser);

      //Rénommez genre
      if (selectedUser.genre !== "F") {
        setGenre("Masculin");
      } else {
        setGenre("Feminin");
      }

      //Rénommez indice
      if (
        selectedUser.ind_resp === "1" ||
        selectedUser.ind_resp === "O" ||
        selectedUser.ind_resp === 1
      ) {
        setIndice("Responsable");
      } else {
        setIndice("Non responsable");
      }

      // Formatage de la date selon le format DD-MM-YYYY
      let dateFormatee = format(new Date(selectedUser.dat_nais), "dd-MM-yyyy");
      setSelecteddateUser(dateFormatee);
    } else {
      console.log("Aucun membre trouvé pour la valeur spécifiée :", userId);
    }
  }

  //pages ajout membre
  function AjouterMembre() {
    const [error, setError] = useState(false);

    const nomRef = useRef();
    const prenomRef = useRef();
    const genreRef = useRef();
    const telRef = useRef();
    const e_mailRef = useRef();
    const adr_voieRef = useRef();
    const adr_cpRef = useRef();
    const adr_villeRef = useRef();
    const ind_respRef = useRef();
    const dat_naisRef = useRef();

    const handleSubmit = async (ev) => {
      ev.preventDefault();
      const membres = {
        nom: nomRef.current.value,
        prenom: prenomRef.current.value,
        genre: genreRef.current.value,
        tel: telRef.current.value,
        e_mail: e_mailRef.current.value,
        adr_voie: adr_voieRef.current.value,
        adr_cp: adr_cpRef.current.value,
        adr_ville: adr_villeRef.current.value,
        ind_resp: ind_respRef.current.value,
      };

      // Vérifier si la date de naissance est renseignée
      if (dat_naisRef.current.value !== "") {
        membres.dat_nais = dat_naisRef.current.value;
      }

      // requête pour ajouter un utilisateurs
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/add_membre`, membres)
        .then((response) => {
          setError(false);
          setMembreId(response.data.utilisateurs.id);
          setAction("section");
        })
        .catch((error) => {
          setError(true);
          console.log(error);
        });
    };
    return (
      <>
        <div className="container">
          <div className="container-form-membre">
            <h2 className="title">Ajouter un Membre</h2>
            <form onSubmit={handleSubmit} id="monFormulaire">
              <div>
                <div className="row">
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">Nom :</label>
                      <span className="error">
                        {/* {error !== 400 ? "" : <p>Login inconnu !</p>} */}
                      </span>
                      <span className="error">
                        {/* {error !== 422 ? "" : <p>Cet utilisateur exist déjà !</p>} */}
                      </span>
                    </div>
                    <div className="block-form">
                      <input
                        type="text"
                        className="input-form"
                        ref={nomRef}
                        placeholder="Entrez votre nom..."
                        required
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">Prénom(s) :</label>
                      <span className="error">
                        {/* {error !== 401 ? "" : <p>Mot de passe invalid !</p>} */}
                      </span>
                    </div>
                    <div className="block-form">
                      <input
                        type="text"
                        className="input-form"
                        ref={prenomRef}
                        placeholder="Entrez votre prénom..."
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">Genre :</label>
                      <span className="error">
                        {/* {error !== 400 ? "" : <p>Login inconnu !</p>} */}
                      </span>
                      <span className="error">
                        {/* {error !== 422 ? "" : <p>Cet utilisateur exist déjà !</p>} */}
                      </span>
                    </div>
                    <div className="block-form">
                      <select
                        className="input-form"
                        id="genre"
                        ref={genreRef}
                        required
                      >
                        <option value="">Genre...</option>
                        <option value="M">Masculin</option>
                        <option value="F">Feminin</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">Téléphone :</label>
                      <span className="error">
                        {/* {error !== 401 ? "" : <p>Mot de passe invalid !</p>} */}
                      </span>
                    </div>
                    <div className="block-form">
                      <input
                        type="tel"
                        className="input-form"
                        ref={telRef}
                        placeholder="Entrez votre numéro téléphone"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="block-label-membre">
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">E-mail :</label>
                      <span className="error">
                        {!error ? "" : <p>Adresse e-mail existant !</p>}
                      </span>
                    </div>
                    <div className="block-form">
                      <input
                        type="email"
                        className="input-form"
                        ref={e_mailRef}
                        placeholder="Entrez votre adresse email..."
                        required
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">
                        Voie adresse :
                      </label>
                      <span className="error">
                        {/* {error !== 401 ? "" : <p>Mot de passe invalid !</p>} */}
                      </span>
                    </div>
                    <div className="block-form">
                      <input
                        type="text"
                        className="input-form"
                        ref={adr_voieRef}
                        placeholder="Entrez votre adresse.."
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">
                        Code Postal :
                      </label>
                      <span className="error">
                        {/* {error !== 400 ? "" : <p>Login inconnu !</p>} */}
                      </span>
                      <span className="error">
                        {/* {error !== 422 ? "" : <p>Cet utilisateur exist déjà !</p>} */}
                      </span>
                    </div>
                    <div className="block-form">
                      <input
                        type="text"
                        className="input-form"
                        ref={adr_cpRef}
                        placeholder="Entrez votre code postal..."
                        required
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">Ville :</label>
                      <span className="error">
                        {/* {error !== 401 ? "" : <p>Mot de passe invalid !</p>} */}
                      </span>
                    </div>
                    <div className="block-form">
                      <input
                        type="text"
                        className="input-form"
                        ref={adr_villeRef}
                        placeholder="Entrez votre ville..."
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div>
                      <label className="block-label-membre">
                        Date de naissance :
                      </label>
                    </div>
                    <div className="block-form">
                      <input
                        type="date"
                        className="input-form"
                        defaultValue={0}
                        ref={dat_naisRef}
                        placeholder="Entrez votre date de naissance..."
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">
                        Indice membre responsable :
                      </label>
                      <span className="error">
                        {/* {error !== 400 ? "" : <p>Login inconnu !</p>} */}
                      </span>
                      <span className="error">
                        {/* {error !== 422 ? "" : <p>Cet utilisateur exist déjà !</p>} */}
                      </span>
                    </div>
                    <div className="block-form">
                      <select
                        className="input-form"
                        id="genre"
                        ref={ind_respRef}
                        required
                      >
                        <option value="">indice...</option>
                        <option value="1">Responsable</option>
                        <option value="0">Non responsable</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" className="btn revoque">
                Suivant
              </button>
            </form>
          </div>
        </div>
      </>
    );
  }

  //pages Modifier membre
  function ModifierMembre() {
    const [error, setError] = useState(false);

    const nomRef = useRef();
    const prenomRef = useRef();
    const genreRef = useRef();
    const telRef = useRef();
    const e_mailRef = useRef();
    const adr_voieRef = useRef();
    const adr_cpRef = useRef();
    const adr_villeRef = useRef();
    const ind_respRef = useRef();
    const dat_naisRef = useRef();

    const handleSubmitModif = async (ev) => {
      ev.preventDefault();

      const membres = {
        nom: nomRef.current.value,
        prenom: prenomRef.current.value,
        genre: genreRef.current.value,
        tel: telRef.current.value,
        e_mail: e_mailRef.current.value,
        adr_voie: adr_voieRef.current.value,
        adr_cp: adr_cpRef.current.value,
        adr_ville: adr_villeRef.current.value,
        ind_resp: ind_respRef.current.value,
      };

      // Vérifier si la date de naissance est renseignée
      if (dat_naisRef.current.value !== "") {
        membres.dat_nais = dat_naisRef.current.value;
      }

      // requête pour ajouter un membre
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/modifier_membre/${selectedUser.id}`,
          membres
        )
        .then((response) => {
          setError(false);
          setAction("modifierSection");
        })
        .catch((error) => {
          setError(true);
          console.log(error);
        });
    };

    const enregistrer = async (ev) => {
      ev.preventDefault();

      const membres = {
        nom: nomRef.current.value,
        prenom: prenomRef.current.value,
        genre: genreRef.current.value,
        tel: telRef.current.value,
        e_mail: e_mailRef.current.value,
        adr_voie: adr_voieRef.current.value,
        adr_cp: adr_cpRef.current.value,
        adr_ville: adr_villeRef.current.value,
        ind_resp: ind_respRef.current.value,
      };

      // Vérifier si la date de naissance est renseignée
      if (dat_naisRef.current.value !== "") {
        membres.dat_nais = dat_naisRef.current.value;
      }

      // requête pour ajouter un membre
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/modifier_membre/${selectedUser.id}`,
          membres
        )
        .then(() => {
          setError(false);
          window.location.reload();
        })
        .catch((error) => {
          setError(true);
          console.log(error);
        });
    };

    return (
      <>
        <div className="container">
          <div className="container-form-membre">
            <h2 className="title">
              Edition Membre :{" "}
              <span className="capitalize">{selectedUser.prenom}</span>
            </h2>
            <form onSubmit={handleSubmitModif} id="monFormulaire">
              <div>
                <div className="row">
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">Nom :</label>
                    </div>
                    <div className="block-form">
                      <input
                        type="text"
                        className="input-form"
                        name="nom"
                        defaultValue={selectedUser.nom}
                        ref={nomRef}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">Prénom(s) :</label>
                    </div>
                    <div className="block-form">
                      <input
                        type="text"
                        className="input-form"
                        name="prenom"
                        defaultValue={selectedUser.prenom}
                        ref={prenomRef}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">Genre :</label>
                    </div>
                    <div className="block-form">
                      <select
                        className="input-form"
                        id="genre"
                        name="nom"
                        ref={genreRef}
                        required
                      >
                        <option value={selectedUser.genre}>
                          {selectedUser.genre === "M" ? "Masculin" : "Feminin"}
                        </option>
                        <option value={selectedUser.genre === "M" ? "F" : "M"}>
                          {selectedUser.genre === "M" ? "Feminin" : "Masculin"}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">Téléphone :</label>
                    </div>
                    <div className="block-form">
                      <input
                        type="tel"
                        className="input-form"
                        name="tel"
                        defaultValue={selectedUser.tel}
                        ref={telRef}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="block-label-membre">
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">E-mail :</label>
                      <span className="error">
                        {!error ? "" : <p>Adresse e-mail existant !</p>}
                      </span>
                    </div>
                    <div className="block-form">
                      <input
                        type="email"
                        className="input-form"
                        name="email"
                        defaultValue={selectedUser.e_mail}
                        ref={e_mailRef}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">
                        Voie adresse :
                      </label>
                    </div>
                    <div className="block-form">
                      <input
                        type="text"
                        className="input-form"
                        name="voie"
                        defaultValue={selectedUser.adr_voie}
                        ref={adr_voieRef}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">
                        Code Postal :
                      </label>
                    </div>
                    <div className="block-form">
                      <input
                        type="text"
                        className="input-form"
                        name="postal"
                        defaultValue={selectedUser.adr_cp}
                        ref={adr_cpRef}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">Ville :</label>
                    </div>
                    <div className="block-form">
                      <input
                        type="text"
                        className="input-form"
                        name="ville"
                        defaultValue={selectedUser.adr_ville}
                        ref={adr_villeRef}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div>
                      <label className="block-label-membre">
                        Date de naissance :
                      </label>
                    </div>
                    <div className="block-form">
                      <input
                        type="date"
                        className="input-form"
                        name="date"
                        defaultValue={selectedUser.dat_nais}
                        ref={dat_naisRef}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">
                        Indice membre responsable :
                      </label>
                    </div>
                    <div className="block-form">
                      <select
                        className="input-form"
                        id="genre"
                        ref={ind_respRef}
                        required
                      >
                        <option value={selectedUser.ind_resp}>
                          {selectedUser.ind_resp === "1" ||
                          selectedUser.ind_resp === "O" ||
                          selectedUser.ind_resp === 1
                            ? "Responsable"
                            : "Non responsable"}
                        </option>
                        <option
                          value={
                            selectedUser.ind_resp === "1" ||
                            selectedUser.ind_resp === "O" ||
                            selectedUser.ind_resp === 1
                              ? 0
                              : 1
                          }
                        >
                          {selectedUser.ind_resp === "1" ||
                          selectedUser.ind_resp === "O" ||
                          selectedUser.ind_resp === 1
                            ? "Non responsable"
                            : "Responsable"}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <button onClick={enregistrer} className="btn">
                Enregistrer
              </button>
              <button type="submit" className="btn revoque ml-5">
                Editer Section
              </button>
            </form>
          </div>
        </div>
      </>
    );
  }

  //pages data du membre selectionné
  function DataMembreChecked() {
    return (
      <>
        <div className="block-liste block-liste-membre mt-30">
          <div className="liste-title">
            <p>MEMBRE</p>
          </div>
          <div className="liste-containt liste-membre">
            <div className="table-membre">
              <div className="data">
                <p className="subtitle">
                  Identifiants :
                  <span className="capitalize">{selectedUser.prenom}</span>
                </p>
                <div className="data-containte">
                  <div className="dataBox">
                    <div className="champ">
                      <label>Matricule :</label>
                      <input
                        type="email"
                        className="input-form input-data"
                        readOnly
                        value={selectedUser.id}
                      />
                    </div>
                    <div className="champ">
                      <label>Nom : </label>
                      <input
                        type="text"
                        className="input-form input-data"
                        id="name"
                        readOnly
                        value={selectedUser.nom}
                      />
                    </div>
                    <div className="champ">
                      <label>Date de naissance :</label>
                      <input
                        type="text"
                        readOnly
                        className="input-form input-data w-small"
                        id="naissance"
                        value={
                          selectedUser.dat_nais === null
                            ? "Non défini"
                            : dateselectedUser
                        }
                      />
                    </div>
                  </div>
                  <div className="dataBox mt-5">
                    <div className="champ">
                      <label>Genre :</label>
                      <input
                        type="text"
                        className="input-form input-data"
                        readOnly
                        value={
                          selectedUser.genre === "M" ? "Masculin" : "Feminin"
                        }
                      />
                    </div>
                    <div className="champ">
                      <label>Prénom(s) :</label>
                      <input
                        type="text"
                        className="input-form input-data"
                        readOnly
                        value={selectedUser.prenom}
                      />
                    </div>
                    <div className="champ"></div>
                  </div>
                </div>
              </div>
              <div className="data">
                <p className="subtitle">Adresse</p>
                <div className="data-containte">
                  <div className="dataBox">
                    <div className="champ">
                      <label>E-mail :</label>
                      <input
                        type="email"
                        className="input-form input-data"
                        readOnly
                        value={selectedUser.e_mail}
                      />
                    </div>
                    <div className="champ">
                      <label>Voie adresse :</label>
                      <input
                        type="text"
                        className="input-form input-data w-small"
                        id="adress"
                        readOnly
                        value={selectedUser.adr_voie}
                      />
                    </div>
                    <div className="champ">
                      <label>Code postal :</label>
                      <input
                        type="text"
                        className="input-form input-data w-small"
                        readOnly
                        value={selectedUser.adr_cp}
                      />
                    </div>
                  </div>
                  <div className="dataBox mt-5">
                    <div className="champ">
                      <label>Ville :</label>
                      <input
                        type="text"
                        className="input-form input-data"
                        readOnly
                        value={selectedUser.adr_ville}
                      />
                    </div>
                    <div className="champ">
                      <label>Indice Resp :</label>
                      <input
                        type="text"
                        className="input-form input-data w-small"
                        readOnly
                        value={
                          selectedUser.ind_resp === "1" ||
                          selectedUser.ind_resp === "O" ||
                          selectedUser.ind_resp === 1
                            ? "Responsable"
                            : "Non responsable"
                        }
                      />
                    </div>
                    <div className="champ"></div>
                  </div>
                </div>
              </div>
              <div className="data section">
                <p className="subtitle">
                  Membre section : {usersId},{" "}
                  <span className="capitalize">{selectedUser.prenom}</span>
                </p>
                <div className="data-containte data-containte-section">
                  <div className="dataBox-sm">
                    <div className="champ">
                      <p>Sampana vokovoko Manga : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SVM")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SVM")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>S Lehilahy Kristianina : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SLK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SLK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>S vehivavy Kristianina : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SVK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SVK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="dataBox-sm mt-10">
                    <div className="champ">
                      <p>Sekoly Alahady : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SA")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SA")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>Antoko Mpihira Fiderana : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("AMF")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("AMF")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>S Tanora Kristianina : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("STK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("STK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="dataBox-sm mt-10">
                    <div className="champ">
                      <p>S Fampianarana T.A : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SFT")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SFT")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>Sampana zava-maneno : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SZM")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SZM")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>Sampana Fifohazana : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SF")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SF")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="dataBox-sm mt-10">
                    <div className="champ">
                      <p>Sampana Tily: </p>
                      <div>
                        <button
                          className={
                            usersect.includes("ST")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("ST")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ"></div>
                    <div className="champ"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  //pages ajouter section membre
  function AjouterSectionMembre() {
    // const [error, setError] = useState(false);
    const [SA, setSA] = useState("");
    const [SLK, setSLK] = useState("");
    const [SVK, setSVK] = useState("");
    const [AMF, setAMF] = useState("");
    const [STK, setSTK] = useState("");
    const [SFT, setSFT] = useState("");
    const [SZM, setSZM] = useState("");
    const [SF, setSF] = useState("");
    const [SVM, setSVM] = useState("");
    const [ST, setST] = useState("");

    const handleSubmit = async (ev) => {
      ev.preventDefault();

      // Définir un tableau de références de sections
      const sectionTous = [SA, SLK, SVK, AMF, STK, SFT, SZM, SF, SVM, ST];

      // Parcourir chaque référence de section
      for (let i = 0; i < sectionTous.length; i++) {
        const sectionValue = sectionTous[i];

        // Vérifier si la valeur de la section est différente de ''
        if (sectionValue !== "") {
          const sections = {
            id_mbr: usersId,
            code_sect: sectionValue,
          };
          // Créer l'objet de section à envoyer à la base de données

          // requête pour ajouter un utilisateur
          await axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/add_membre_section`,
              sections
            )
            .then((response) => {
              console.log(response.data.Section_Membre);
            })
            .catch((error) => {
              console.log("Erreur :", error);
            });
        }

        if (i === sectionTous.length - 1) {
          window.location.reload(); // Actualise la page
        }
      }
    };

    const handleSubmission = (e, setter, value) => {
      e.preventDefault();
      setter(value);
    };

    const RadioButton = ({ onClick, checked, label }) => {
      return (
        <button
          onClick={onClick}
          className={checked ? "radio-binary cheked" : "radio-binary"}
        >
          {label}
        </button>
      );
    };

    const Ignorez = (e) => {
      e.preventDefault();
      window.location.reload(); // Actualise la page
    };

    return (
      <>
        <div className="container">
          <div className="container-form-section">
            <p className="title">
              <span className="capitalize">assigner section membre</span>
            </p>
            <div>
              <div className="blockSection">
                <div className="assigne">
                  <p className="">Sampana Sekoly Alahady</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSA, "")}
                      checked={SA !== "SA"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSA, "SA")}
                      checked={SA === "SA"}
                      label="Oui"
                    />
                  </div>
                </div>
                <div className="assigne">
                  <p className="">Sampana Lehilahy Kristianina</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSLK, "")}
                      checked={SLK !== "SLK"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSLK, "SLK")}
                      checked={SLK === "SLK"}
                      label="Oui"
                    />
                  </div>
                </div>
              </div>
              <div className="blockSection">
                <div className="assigne">
                  <p className="">Sampana Vehivavy Kristianina</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSVK, "")}
                      checked={SVK !== "SVK"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSVK, "SVK")}
                      checked={SVK === "SVK"}
                      label="Oui"
                    />
                  </div>
                </div>
                <div className="assigne">
                  <p className="">Antoko Mpihira Fiderana</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setAMF, "")}
                      checked={AMF !== "AMF"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setAMF, "AMF")}
                      checked={AMF === "AMF"}
                      label="Oui"
                    />
                  </div>
                </div>
              </div>
              <div className="blockSection">
                <div className="assigne">
                  <p className="">Sampana Tanora Kristianina</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSTK, "")}
                      checked={STK !== "STK"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSTK, "STK")}
                      checked={STK === "STK"}
                      label="Oui"
                    />
                  </div>
                </div>
                <div className="assigne">
                  <p className="">Sampana Fampianarana T.A</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSFT, "")}
                      checked={SFT !== "SFT"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSFT, "SFT")}
                      checked={SFT === "SFT"}
                      label="Oui"
                    />
                  </div>
                </div>
              </div>
              <div className="blockSection">
                <div className="assigne">
                  <p className="">Sampana Zava-maneno</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSZM, "")}
                      checked={SZM !== "SZM"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSZM, "SZM")}
                      checked={SZM === "SZM"}
                      label="Oui"
                    />
                  </div>
                </div>
                <div className="assigne">
                  <p className="">Sampana Fifohazana</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSF, "")}
                      checked={SF !== "SF"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSF, "SF")}
                      checked={SF === "SF"}
                      label="Oui"
                    />
                  </div>
                </div>
              </div>
              <div className="blockSection">
                <div className="assigne">
                  <p className="">Sampana Vokovoko Manga</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSVM, "")}
                      checked={SVM !== "SVM"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSVM, "SVM")}
                      checked={SVM === "SVM"}
                      label="Oui"
                    />
                  </div>
                </div>
                <div className="assigne">
                  <p className="">Sampana Tily</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setST, "")}
                      checked={ST !== "ST"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setST, "ST")}
                      checked={ST === "ST"}
                      label="Oui"
                    />
                  </div>
                </div>
              </div>
            </div>
            <button onClick={handleSubmit} className="btn revoque">
              Assigner
            </button>
            <button onClick={Ignorez} className="btn ml-5">
              Ignorer
            </button>
          </div>
        </div>
      </>
    );
  }

  //pages ajouter section membre
  function ModifierSectionMembre() {
    // const [default, setDefault] = useState(false);

    const [SA, setSA] = useState(!usersect.includes("SA") ? "" : "SA");
    const [SLK, setSLK] = useState(!usersect.includes("SLK") ? "" : "SLK");
    const [SVK, setSVK] = useState(!usersect.includes("SVK") ? "" : "SVK");
    const [AMF, setAMF] = useState(!usersect.includes("AMF") ? "" : "AMF");
    const [STK, setSTK] = useState(!usersect.includes("STK") ? "" : "STK");
    const [SFT, setSFT] = useState(!usersect.includes("SFT") ? "" : "SFT");
    const [SZM, setSZM] = useState(!usersect.includes("SZM") ? "" : "SZM");
    const [SF, setSF] = useState(!usersect.includes("SF") ? "" : "SF");
    const [SVM, setSVM] = useState(!usersect.includes("SVM") ? "" : "SVM");
    const [ST, setST] = useState(!usersect.includes("ST") ? "" : "ST");

    // Fonction pour déterminer si la section est présente dans les sections de l'utilisateur
    // const isSectionSelected = (section) => {
    //   if(usersect.includes(section)){
    //     setDefault(true)
    //   }
    //   return usersect.includes(section);
    // };

    const handleSubmit = async (ev) => {
      ev.preventDefault();

      if (usersect.length !== 0) {
        // requête pour suprimer un utilisateur
        await axios
          .get(
            `${process.env.REACT_APP_BACKEND_URL}/delete_membre_section/${usersId}`
          )
          .then((response) => {
            console.log(response.msg);
          })
          .catch((error) => {
            console.log("Erreur :", error);
          });
      }

      // Définir un tableau de références de sections
      const sectionTousModif = [SA, SLK, SVK, AMF, STK, SFT, SZM, SF, SVM, ST];

      // Parcourir chaque référence de section
      for (let i = 0; i < sectionTousModif.length; i++) {
        const sectionValueModif = sectionTousModif[i];

        // Vérifier si la valeur de la section est différente de ''
        if (sectionValueModif !== "") {
          const sections = {
            id_mbr: usersId,
            code_sect: sectionValueModif,
          };

          //Créer l'objet de section à envoyer à la base de données
          //requête pour ajouter un utilisateur
          await axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/add_membre_section`,
              sections
            )
            .then((response) => {
              console.log(response.data.Section_Membre);
            })
            .catch((error) => {
              console.log("Erreur :", error);
            });
        }
        if (i === sectionTousModif.length - 1) {
          window.location.reload(); // Actualise la page
        }
      }
    };

    const handleSubmission = (e, setter, value) => {
      e.preventDefault();
      setter(value);
    };

    const RadioButton = ({ onClick, checked, label }) => {
      return (
        <button
          onClick={onClick}
          className={checked ? "radio-binary cheked" : "radio-binary"}
        >
          {label}
        </button>
      );
    };

    const Ignorez = (e) => {
      e.preventDefault();
      window.location.reload(); // Actualise la page
    };

    return (
      <>
        <div className="container">
          <div className="container-form-section">
            <p className="title">
              <span className="capitalize">assigner section membre</span>
            </p>
            <div>
              <div className="blockSection">
                <div className="assigne">
                  <p className="">Sampana Sekoly Alahady</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSA, "")}
                      checked={SA !== "SA"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSA, "SA")}
                      checked={SA === "SA"}
                      label="Oui"
                    />
                  </div>
                </div>
                <div className="assigne">
                  <p className="">Sampana Lehilahy Kristianina</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSLK, "")}
                      checked={SLK !== "SLK"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSLK, "SLK")}
                      checked={SLK === "SLK"}
                      label="Oui"
                    />
                  </div>
                </div>
              </div>
              <div className="blockSection">
                <div className="assigne">
                  <p className="">Sampana Vehivavy Kristianina</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSVK, "")}
                      checked={SVK !== "SVK"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSVK, "SVK")}
                      checked={SVK === "SVK"}
                      label="Oui"
                    />
                  </div>
                </div>
                <div className="assigne">
                  <p className="">Antoko Mpihira Fiderana</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setAMF, "")}
                      checked={AMF !== "AMF"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setAMF, "AMF")}
                      checked={AMF === "AMF"}
                      label="Oui"
                    />
                  </div>
                </div>
              </div>
              <div className="blockSection">
                <div className="assigne">
                  <p className="">Sampana Tanora Kristianina</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSTK, "")}
                      checked={STK !== "STK"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSTK, "STK")}
                      checked={STK === "STK"}
                      label="Oui"
                    />
                  </div>
                </div>
                <div className="assigne">
                  <p className="">Sampana Fampianarana T.A</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSFT, "")}
                      checked={SFT !== "SFT"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSFT, "SFT")}
                      checked={SFT === "SFT"}
                      label="Oui"
                    />
                  </div>
                </div>
              </div>
              <div className="blockSection">
                <div className="assigne">
                  <p className="">Sampana Zava-maneno</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSZM, "")}
                      checked={SZM !== "SZM"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSZM, "SZM")}
                      checked={SZM === "SZM"}
                      label="Oui"
                    />
                  </div>
                </div>
                <div className="assigne">
                  <p className="">Sampana Fifohazana</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSF, "")}
                      checked={SF !== "SF"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSF, "SF")}
                      checked={SF === "SF"}
                      label="Oui"
                    />
                  </div>
                </div>
              </div>
              <div className="blockSection">
                <div className="assigne">
                  <p className="">Sampana Vokovoko Manga</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSVM, "")}
                      checked={SVM !== "SVM"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSVM, "SVM")}
                      checked={SVM === "SVM"}
                      label="Oui"
                    />
                  </div>
                </div>
                <div className="assigne">
                  <p className="">Sampana Tily</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setST, "")}
                      checked={ST !== "ST"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setST, "ST")}
                      checked={ST === "ST"}
                      label="Oui"
                    />
                  </div>
                </div>
              </div>
            </div>
            <button onClick={handleSubmit} className="btn revoque">
              Assigner
            </button>
            <button onClick={Ignorez} className="btn ml-5">
              Ignorer
            </button>
          </div>
        </div>
      </>
    );
  }

  //Fonction pour exporter tous
  const tousExporter = (e) => {
    e.preventDefault();

    // Vérifier si des utilisateurs sont disponibles
    if (!users || users.length === 0) {
      console.error("Aucun utilisateur à exporter.");
      return;
    }

    // Créer les données pour l'utilisateur sélectionné
    const data = users.map((user) => ({
      nom: user.nom,
      prénom: user.prenom,
      genre: user.genre === "F" ? "Féminin" : "Masculin",
      tel: user.tel,
      e_mail: user.e_mail,
      adr_voie: user.adr_voie,
      adr_cp: user.adr_cp,
      adr_ville: user.adr_ville,
      ind_resp:
        user.ind_resp === 1 || user.ind_resp === "1" || user.ind_resp === "O"
          ? "Responsable"
          : "Non responsable",
      dat_nais: format(new Date(user.dat_nais), "dd-MM-yyyy"),
    }));

    // Créer une feuille de calcul
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Membres");

    // Générer le fichier en mémoire
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    // Convertir le fichier en blob
    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    // Télécharger le fichier
    saveAs(blob, "membres.xlsx");
  };

  // Fonction pour convertir une chaîne en tableau de bytes
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  //Exporter un utilisateurs
  const oneExporter = (e) => {
    e.preventDefault();

    // Vérifier si un utilisateur est sélectionné
    if (!selectedUser) {
      console.error("Aucun utilisateur sélectionné.");
      return;
    }

    // Formatage de la date selon le format DD-MM-YYYY
    let dateFormatee = format(new Date(selectedUser.dat_nais), "dd-MM-yyyy");

    // Créer les données pour l'utilisateur sélectionné
    const data = [
      {
        prénom: selectedUser.prenom,
        nom: selectedUser.nom,
        genre: genreUser,
        tel: selectedUser.tel,
        e_mail: selectedUser.e_mail,
        adr_voie: selectedUser.adr_voie,
        adr_cp: selectedUser.adr_cp,
        adr_ville: selectedUser.adr_ville,
        ind_resp: indiceUser,
        dat_nais: dateFormatee,
      },
    ];

    // Créer une feuille de calcul
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Membres");

    // Sauvegarder le fichier
    const fileName = `${selectedUser.prenom}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  //pagination
  const usersPerPage = 8;

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  // Utilisez la liste filtrée si une recherche a été effectuée, sinon utilisez la liste complète
  const filteredUsers = searchPerformed
    ? users.filter((user) => {
        const nameMatch = user.nom
          .toLowerCase()
          .includes(searchName.toLowerCase());
        const firstNameMatch = user.prenom
          .toLowerCase()
          .includes(searchFirstName.toLowerCase());
        return nameMatch && firstNameMatch;
      })
    : users;

  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchPerformed(true); // Indique qu'une recherche a été effectuée
    setCurrentPage(1); // Réinitialisez la pagination à la première page lors de la recherche
  };

  // Fonction pour changer de page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //gestion pages avec button
  const renderForm = () => {
    switch (action) {
      case "ajouter":
        return <AjouterMembre />;
      case "modifier":
        return <ModifierMembre />;
      case "checked":
        return <DataMembreChecked />;
      case "section":
        return <AjouterSectionMembre />;
      case "modifierSection":
        return <ModifierSectionMembre />;
      default:
        return <DataMembreChecked />;
    }
  };

  return (
    <>
      <div className="container">
        <div className="container-lg">
          <div className="block-liste block-liste-cherche mt-30">
            <div className="liste-title">
              <p>RECHERCHE</p>
            </div>
            <form onSubmit={handleSearch}>
              <div className="liste-recherche">
                <div className="champ-cherche">
                  <div>
                    <label for="" className="label-form">
                      Nom :{" "}
                    </label>
                    <input
                      type="text"
                      id="id"
                      name="name"
                      className="input-form mt-10"
                      value={searchName}
                      onChange={(e) => setSearchName(e.target.value)}
                      placeholder="Entrez le nom à rechercher..."
                    />
                  </div>
                </div>
                <div className="champ-cherche">
                  <div>
                    <label for="" className="label-form">
                      Prénom :{" "}
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="input-form mt-10"
                      value={searchFirstName}
                      onChange={(e) => setSearchFirstName(e.target.value)}
                      placeholder="Entrez le prénom à rechercher..."
                    />
                  </div>
                </div>
                <div className="champ-cherche">
                  <div>
                    <label for="" className="label-form"></label>
                    <div className="">
                      <button type="submit" className="btn ml-5">
                        Rechercher
                      </button>
                      <button
                        type="button"
                        className="btn ml-5"
                        onClick={() => {
                          setSearchName("");
                          setSearchFirstName("");
                          setSearchPerformed(false);
                          setMembreId("");
                          setAction("");
                          setActiveInput("");
                          show_membre_section("");
                          setSelectedUser([]);
                          // Réinitialiser la sélection de l'utilisateur
                          const radioButtons = document.querySelectorAll(
                            'input[type="radio"][name="selectedUser"]'
                          );
                          radioButtons.forEach((button) => {
                            button.checked = false;
                          });
                        }}
                      >
                        Réinitialiser
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="bar mt-30"></div>
          <div className="block-liste">
            <div className="liste-title">
              <p>LISTE DES UTILISATEURS</p>
            </div>
            <div className="liste-containt">
              <div className="table">
                <div className="theader">
                  <div className="check">
                    <div className="box"></div>
                  </div>
                  <div className="w-100">N° :</div>
                  <div className="w-200">Nom</div>
                  <div className="w-200">Prénom</div>
                  <div className="w-300">E-mail</div>
                  <div className="w-200">Adresse</div>
                  <div className="w-200">Téléphone</div>
                </div>
                <div className="tbody">
                  {currentUsers.map((user, index) => (
                    <div key={user.id} className="liste-value">
                      <div>
                        <input
                          className="check check-box"
                          type="radio"
                          name="selectedUser"
                          value={user.id}
                          onChange={(event) => handleInputChange(event)}
                        />
                      </div>
                      <div className="w-100">{index + 1}</div>
                      <div className="w-200">{user.nom}</div>
                      <div className="w-200">{user.prenom}</div>
                      <div className="w-300">{user.e_mail}</div>
                      <div className="w-200">{user.adr_voie}</div>
                      <div className="w-200">{user.tel}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="button-modif button-modif-xxl">
            <div className="button-modif-action">
              <button
                className="btn-param"
                onClick={() => {
                  setAction("ajouter");
                  setActiveInput(""); //   Réinitialiser la sélection de l'utilisateur
                  const radioButtons = document.querySelectorAll(
                    'input[type="radio"][name="selectedUser"]'
                  );
                  radioButtons.forEach((button) => {
                    button.checked = false;
                  });
                }}
              >
                Ajouter
              </button>
              <button
                className={!activeInput ? "display-none" : "btn-param ml-5"}
                onClick={() => setAction("modifier")}
              >
                Modifier
              </button>
              <button
                className={
                  !activeInput ? "display-none" : "btn-param revoque ml-5"
                }
                onClick={oneExporter}
              >
                Exporter
              </button>
              <button className="btn-param revoque ml-5" onClick={tousExporter}>
                Tout exporter
              </button>
            </div>
            <div className="pagination">
              <div className="right-page">
                {Array.from(
                  {
                    length: Math.ceil(filteredUsers.length / usersPerPage),
                  },
                  (_, index) => {
                    const startPage = Math.max(currentPage - 2, 0);
                    const endPage = Math.min(
                      startPage + 3,
                      Math.ceil(filteredUsers.length / usersPerPage)
                    );

                    if (index >= startPage && index < endPage) {
                      return (
                        <button
                          key={index}
                          onClick={() => paginate(index + 1)} // Appeler la fonction paginate avec le numéro de page correspondant
                          className={
                            currentPage === index + 1
                              ? "pageNum active"
                              : "pageNum"
                          }
                        >
                          {index + 1}
                        </button>
                      );
                    } else if (index === startPage - 1 && currentPage > 3) {
                      return <span key="ellipsis-start">...</span>;
                    } else if (
                      index === endPage &&
                      currentPage <
                        Math.ceil(filteredUsers.length / usersPerPage) - 2
                    ) {
                      return <span key="ellipsis-end">...</span>;
                    }
                    return null;
                  }
                )}
              </div>
            </div>
          </div>

          <div className="bar"></div>
          <div className="mt-30">{renderForm()}</div>
          <div className="bar mt-30"></div>
          <div className="mt-30 text-center">@ProjetAdidy</div>
        </div>
      </div>
    </>
  );
}

export default MembreAdminPage;
