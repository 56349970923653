import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

// Composant de formulaire pour la modification d'utilisateur
function ModifierPasswordForm({ userlogin }) {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const loginRef = userlogin;
  const passwordRef = useRef();
  const password_confirmationRef = useRef();

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    const utilisateurs = {
      login: loginRef,
      password: passwordRef.current.value,
      password_confirmation: password_confirmationRef.current.value,
    };

    console.log(utilisateurs);

    // requête changer mot de passe utilisateur connecté
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/change_password_user`,
        utilisateurs,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`, //authorization token pour récupérer l'utilisateurs
          },
        }
      )
      .then((data) => {
        document.getElementById("monFormulaire").reset();
        setError(null);
        setSuccess(data.data.msg);
      })
      .catch((error) => {
        setSuccess(null);
        setError(error);
      });
  };

  return (
    //Formulaire pour Modifier Mot de passe
    <>
      <div className="container">
        <div className="container-form">
          <h2 className="title">Modifier mon mot de passe</h2>
          <form onSubmit={handleSubmit} id="monFormulaire">
            <div>
              <div className="block-label">
                <label htmlFor="login" className="block-label">
                  Your Login :
                </label>
              </div>
              <div className="block-form">
                <input
                  type="email"
                  className="input-form"
                  readOnly
                  id="login"
                  value={userlogin}
                />
              </div>
            </div>
            <div>
              <div className="block-label">
                <span className="obligatoire">*</span>
                <label htmlFor="password" className="block-label">
                  Nouveau mot de passe :
                </label>
                <span className="error">
                  {!error ? "" : <p>Mot de passe invalid !</p>}
                </span>
              </div>
              <div className="block-form">
                <input
                  type="password"
                  className="input-form"
                  id="password"
                  ref={passwordRef}
                  placeholder="Entrez votre mot de passe"
                  required
                />
              </div>
            </div>
            <div>
              <div className="block-label">
                <span className="obligatoire">*</span>
                <label htmlFor="login" className="block-label">
                  Confirmez mot de passe :
                </label>
                <span className="error">
                  {!error ? "" : <p>Mot de passe invalid !</p>}
                </span>
              </div>
              <div className="block-form">
                <input
                  type="password"
                  className="input-form"
                  id="password_confirmation"
                  ref={password_confirmationRef}
                  placeholder="Confirmez le mot de passe"
                  required
                />
              </div>
            </div>
            <button type="submit" className="btn revoque">
              Valider
            </button>
            <div className="block-form">
              {!success ? (
                ""
              ) : (
                <div className="mt-30 text-success">{success}</div>
              )}
            </div>
          </form>
        </div>
      </div>
      <div className="mt-30 text-center">@ProjetAdidy</div>
    </>
  );
}

// Composant de Lorem ipsum
function LoremForm() {
  return <h2 className="mt-2 title text-center">Lorem Ipsum Dolor set</h2>;
}

function Users() {
  const [action, setAction] = useState(null);
  const [users, setUsers] = useState([]);
  const [userlogin, setUserLogin] = useState();
  const [utilisateurs, setUtilisateurs] = useState([]);

  const renderForm = () => {
    switch (action) {
      case "modifier":
        return <ModifierPasswordForm userlogin={userlogin} />;
      case "lorem":
        return <LoremForm />;
      default:
        return <LoremForm />;
    }
  };

  // Envoyer une requête GET pour récupérer l'utilisateurs connecté
  useEffect(() => {
    const show = async () => {
      await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/user`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`, //authorization token pour récupérer l'utilisateurs
          },
        })
        .then((response) => {
          setUsers(response.data.user); //user
          setUtilisateurs(response.data.utilisateurs);
          setUserLogin(response.data.user.login);
        })
        .catch((error) => {
          console.error(
            "Une erreur s'est produite lors du chargement des données:",
            error
          );
        });
    };

    show();
  }, [setUsers]);
  return (
    <>
      <div className="container-fluide-user">
        <div className="containt-user">
          <div className="photo">
            <div className="userphoto"></div>
          </div>
          <div className="data-user">
            <p>
              Profil :{" "}
              {users.profil === "ADM" ? "Administrateur" : "Utilisateur"}
            </p>
            <p className="mt-20">Nom : {utilisateurs.nom}</p>
            <p className="mt-20">Prénom : {utilisateurs.prenom}</p>
            <p className="mt-20">
              Genre : {utilisateurs.genre === "F" ? "Feminin" : "Masculin"}
            </p>
            <p className="mt-20">
              Autre déscription : Lorem ipsum, dolor sit amet consectetur
              adipisicing elit. Saepe odio magnam corrupti perspiciatis ipsam
              laborum recusandae expedita ratione consequatur aliquid deserunt
              blanditiis nesciunt distinctio ullam alias a porro culpa iusto
              minus doloribus incidunt rerum voluptate, velit nam? Enim quasi
              eveniet repellat assumenda facilis harum velit quidem. Non quia
              cum tenetur eum sit velit, nihil aliquam animi repudiandae tempore
              et deserunt natus nostrum corporis quaerat. Distinctio voluptas
              fugit reprehenderit. Dolorum velit aspernatur itaque eligendi,
              dolore modi inventore magni autem, iure assumenda aperiam sunt
              nam. Numquam possimus quibusdam, sapiente reprehenderit totam
              similique sed sint doloribus illo dicta tenetur corrupti, nostrum
              sit non.
            </p>
            <button
              className="btn-param mt-30"
              onClick={() => {
                setAction("modifier");
              }}
            >
              Modifier password
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="bar mt-30 bar-1200"></div>
      </div>
      <div className="mt-30">{renderForm()}</div>
    </>
  );
}

export default Users;
