import React from 'react';

function SelectEmail() {
  return (
    <div className="container">
      <h2>Entrez votre email</h2>
    </div>
  );
}

export default SelectEmail;