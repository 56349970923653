import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import UtilisateursPage from "./Param_utilisateur";
import ReferencesPage from "./Param_reference";

function Parametrage() {
  const navigate = useNavigate();
  const [role, setRole] = useState();
  const [users, setUtilisateurs] = useState([]);
  const [currentPage, setCurrentPage] = useState("utilisateurs");


  //sécurisé parametrage d'un code url, redirection pages not fund
  useEffect(() => {
    if (role === "USR") {
      navigate("/notFund");
    }
  }, [role, navigate]);

  //Admin role, récuperation de tous les utilisateurs
  const show_utilisateur = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/show_utilisateur`)
      .then((response) => {
        setUtilisateurs(response.data.utilisateurs);
      })
      .catch((error) => {
        console.error(
          "Une erreur s'est produite lors du chargement des données:",
          error
        );
      });
  };

  useEffect(() => {
    show_utilisateur();
  }, []);

  //récupérer les données d'utilisateur connecter
  const show = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/user`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      })
      .then((response) => {
        setRole(response.data.user.profil);
      })
      .catch((error) => {
        console.error(
          "Une erreur s'est produite lors du chargement des données:",
          error
        );
      });
  };

  useEffect(() => {
    show();
  }, []);
  //pages utilisateurs name
  const utilisateurPages = () => {
    setCurrentPage("utilisateurs");
  };

  //pages referances name
  const referencePages = () => {
    setCurrentPage("reference");
  };

  const checkActivateUTL = ()=>{
    if (currentPage === 'utilisateurs')
    {
      return 'pageUTL';
    }else {
      return 'notUTL';
    }
  }

  const checkActivateREF = ()=>{
    if (currentPage === 'utilisateurs')
    {
      return 'notUTL';
    }else {
      return 'pageUTL';
    }
  }

  const renderPageContent = () => {
    if (currentPage === "utilisateurs") {
      return <UtilisateursPage users={users} />;
    } else if (currentPage === "reference") {
      return <ReferencesPage />;
    }
  };

  return (
    <div className="container-fluide">
      <div className="container-lg">
        <div className="param-nav">
          <button className={checkActivateUTL()} onClick={utilisateurPages}>
            Utilisateurs
          </button>
          <button className={checkActivateREF()} onClick={referencePages}>
            Références
          </button>
        </div>
        <div className="bar"></div>
        <div>
          <div className="">{renderPageContent()}</div>
        </div>
      </div>
    </div>
  );
}

export default Parametrage;
