import axios from "axios";
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { format } from "date-fns";

function Cotisation_user() {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [cotisation, setCotisation] = useState([]);
  const [action, setAction] = useState("");
  const [utilisateur, setUtilisateur] = useState(null); // Initialement, pas d'utilisateur
  const [errorStatus, setErrorMessage] = useState(null);

  // Envoyer une requête GET pour récupérer l'utilisateur connecté
  const show = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/user`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`, // Authorization token pour récupérer l'utilisateur
          },
        }
      );
      setUsers(response.data.user);
      setLoading(false);
      setUtilisateur(response.data.utilisateurs); // Définir l'utilisateur ici
    } catch (error) {
      console.log(error);
    }
  };

  const show_cotisation = async () => {
    try {
      if (utilisateur) {
        // S'assurer que l'utilisateur est défini
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/show_cotisation_users/${utilisateur.id}`
        );
        setAction("data");
        setCotisation(response.data.cotisation);
      } else {
        console.log("L'utilisateur n'est pas encore défini.");
      }
    } catch (error) {
      setAction("error");
      console.error(error);
      setErrorMessage(error.response.data.status);
    }
  };

  useEffect(() => {
    show(); // Appel pour récupérer l'utilisateur
  }, []);

  useEffect(() => {
    if (utilisateur) {
      // Si l'utilisateur est défini, alors appeler show_cotisation
      show_cotisation();
    }
  }, [utilisateur]); // Déclencher ce useEffect lorsque utilisateur est mis à jour

  if (loading) {
    return (
      <>
        <div className="loading">
          <div className="loader-wrapper">
            <div className="loader"></div>
          </div>
        </div>
      </>
    );
  }

  function ErrorPage() {
    return (
      <>
        <div className="text-center">
          Nom : {utilisateur.nom}, Matricule : {users.id_mbr};
        </div>
        <div className="title text-center">
          <p className={!errorStatus ? "none" : "text-center mt-10"}>
            {errorStatus === 401
              ? "Vous n'avez aucun cotisation !"
              : errorStatus === 422
              ? "Id membre inconnu !"
              : ""}
          </p>
        </div>
      </>
    );
  }

  function DataPage() {
    const [clickedA, setclickedAdidy] = useState(false);
    const [clickedD, setclickedDon] = useState(false);
    const [clickedS, setclickedSous] = useState(false);
    const [clickedESP, setclickedESP] = useState(false);
    const [clickedVIR, setclickedVIR] = useState(false);
    const [clickedCHE, setclickedCHE] = useState(false);
    const [clickedCB, setclickedCB] = useState(false);
    const [searchAnnee, setSearchAnnee] = useState(new Date().getFullYear().toString());
    const [sercheTyp_pmt, setsearchTyp_pmt] = useState("");
    const [sercheMode_pmt, setsearchMode_pmt] = useState("");
    const [actionCheck, setActionCheck] = useState(null);
    const [selectedUser, setSelectedUser] = useState([]);
    const [cotisationSelectedTyp, setSelectedCotisationTyp] = useState([]);
    const [cotisationSelectedMt, setSelectedCotisationMt] = useState([]);
    const [dateselectedUser, setSelecteddateUser] = useState([]);

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [searchPerformed, setSearchPerformed] = useState(false);
    const cotisationPerPage = 8;

    const handleClick = (state, setState) => {
      // Inverser la valeur de l'état lors du clic
      setState((prevState) => !prevState);
    };

    // Gestionnaire d'événements pour la modification de la cotisation sélectionnée
    function handleInputChange(event) {
      const userId = event.target.value;
      setActionCheck("cheked");

      // Recherche de cotisation par son ID
      const selectedCotisation = cotisation.find(
        (user) => user.id === parseInt(userId)
      );

      // Vérifier si la cotisation sélectionnée existe dans les utilisateurs
      if (selectedCotisation) {
        setSelectedCotisationTyp(selectedCotisation.typ_mt);
        setSelectedCotisationMt(selectedCotisation.mt);
        setSelectedUser(selectedCotisation);

        let dateFormatee = format(
          new Date(selectedCotisation.dat_pmt),
          "dd-MM-yyyy"
        );
        setSelecteddateUser(dateFormatee);
      }
    }

    const handleSearch = (e) => {
      e.preventDefault();
      setSearchPerformed(true); // Indique qu'une recherche a été effectuée
      setCurrentPage(1); // Réinitialiser la pagination à la première page lors de la recherche
    };

    //Fonction pour exporter tous
    const tousExporter = (e) => {
      e.preventDefault();

      // Vérifier si des utilisateurs sont disponibles
      if (!cotisation || cotisation.length === 0) {
        console.error("Aucun utilisateur à exporter.");
        return;
      }

      // Créer les données pour l'utilisateur sélectionné
      const data = cotisation.map((user) => ({
        Matricule: user.id_mbr,
        Nom: user.nom,
        Prénom: user.prenom,
        Mode_paiement: user.mod_pmt,
        Montant: user.mt,
        Date_paiement: format(new Date(user.dat_pmt), "dd-MM-yyyy"),
        Type_paiement: user.typ_mt,
        Notes: user.notes,
        Id_recu: user.id_recu,
        Mois_deb: user.mm_deb,
        Mois_fin: user.mm_fin,
        Ann_deb: user.aa_deb,
        Ann_fin: user.aa_fin,
      }));

      // Créer une feuille de calcul
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Cotisations");

      // Générer le fichier en mémoire
      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      // Convertir le fichier en blob
      const blob = new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
      });

      // Télécharger le fichier
      saveAs(blob, "cotisations.xlsx");
    };

    // Fonction pour convertir une chaîne en tableau de bytes
    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    };

    //Exporter un utilisateurs
    const oneExporter = (e) => {
      e.preventDefault();

      // Vérifier si un utilisateur est sélectionné
      if (!selectedUser) {
        console.error("Aucun utilisateur sélectionné.");
        return;
      }

      // Formatage de la date selon le format DD-MM-YYYY
      let dateFormatee = format(new Date(selectedUser.dat_pmt), "dd-MM-yyyy");

      // Créer les données pour l'utilisateur sélectionné
      const data = [
        {
          Matricule: selectedUser.id_mbr,
          Nom: selectedUser.nom,
          Prénom: selectedUser.prenom,
          Mode_paiement: selectedUser.mod_pmt,
          Montant: selectedUser.mt,
          Date_paiement: dateFormatee,
          Type_paiement: selectedUser.typ_mt,
          Notes: selectedUser.notes,
          Id_recu: selectedUser.id_recu,
          Mois_deb: selectedUser.mm_deb,
          Mois_fin: selectedUser.mm_fin,
          Ann_deb: selectedUser.aa_deb,
          Ann_fin: selectedUser.aa_fin,
        },
      ];

      // Créer une feuille de calcul
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Cotisations");

      // Sauvegarder le fichier
      const fileName = `${selectedUser.prenom}.xlsx`;
      XLSX.writeFile(wb, fileName);
    };

    // Fonction pour changer de page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Utiliser la liste filtrée si une recherche a été effectuée, sinon utiliser la liste complète
    const filteredCotisation = searchPerformed
      ? cotisation.filter((user) => {
          const anneeMatch = new Date(user.dat_pmt).getFullYear()
            .toString()
            .toLowerCase()
            .includes(searchAnnee.toLowerCase());
          const typ_pmt = user.typ_mt
            .toString()
            .toLowerCase()
            .includes(sercheTyp_pmt.toLowerCase());
          const mode_pmt = user.mod_pmt
            .toString()
            .toLowerCase()
            .includes(sercheMode_pmt.toLowerCase());
          return anneeMatch && typ_pmt && mode_pmt;
        })
      : cotisation;

    const indexOfLastUser = currentPage * cotisationPerPage;
    const indexOfFirstUser = indexOfLastUser - cotisationPerPage;
    const currentCotisation = cotisation
      ? filteredCotisation.slice(indexOfFirstUser, indexOfLastUser)
      : [];

    return (
      <>
        <div className="block-liste block-liste-cherche mt-30">
          <div className="liste-title">
            <p>RECHERCHE</p>
          </div>
          <form onSubmit={handleSearch}>
            <div className="liste-recherche">
              <div className="champ-cherche-xsm">
                <label className="label-form">Type cotisation : </label>
                <div className="btn-filter">
                  <button
                    type=""
                    className={
                      sercheTyp_pmt === "A"
                        ? "radio-binary radio-binary-lg cheked"
                        : "radio-binary radio-binary-lg"
                    }
                    onClick={() => {
                      handleClick(clickedA, setclickedAdidy);
                      setclickedDon("");
                      setclickedSous("");
                      setsearchTyp_pmt("A");
                    }}
                  >
                    A
                  </button>
                  <button
                    type=""
                    className={
                      sercheTyp_pmt === "D"
                        ? "radio-binary radio-binary-lg cheked"
                        : "radio-binary radio-binary-lg"
                    }
                    onClick={() => {
                      handleClick(clickedD, setclickedDon);
                      setclickedAdidy("");
                      setclickedSous("");
                      setsearchTyp_pmt("D");
                    }}
                  >
                    D
                  </button>
                  <button
                    type=""
                    className={
                      sercheTyp_pmt === "S"
                        ? "radio-binary radio-binary-lg cheked"
                        : "radio-binary radio-binary-lg"
                    }
                    onClick={() => {
                      handleClick(clickedS, setclickedSous);
                      setclickedAdidy("");
                      setclickedDon("");
                      setsearchTyp_pmt("S");
                    }}
                  >
                    S
                  </button>
                </div>
              </div>
              <div className="champ-cherche-xsm">
                <label className="label-form">Mode paiement : </label>
                <div className="btn-filter">
                  <button
                    type=""
                    className={
                      sercheMode_pmt === "ESP"
                        ? "radio-binary radio-binary-lg cheked"
                        : "radio-binary radio-binary-lg"
                    }
                    onClick={() => {
                      handleClick(clickedESP, setclickedESP);
                      setclickedCHE("");
                      setclickedVIR("");
                      setclickedCB("");
                      setsearchMode_pmt("ESP");
                    }}
                  >
                    ESP
                  </button>
                  <button
                    type=""
                    className={
                      sercheMode_pmt === "CHE"
                        ? "radio-binary radio-binary-lg cheked"
                        : "radio-binary radio-binary-lg"
                    }
                    onClick={() => {
                      handleClick(clickedCHE, setclickedCHE);
                      setclickedESP("");
                      setclickedVIR("");
                      setclickedCB("");
                      setsearchMode_pmt("CHE");
                    }}
                  >
                    CHE
                  </button>
                  <button
                    type=""
                    className={
                      sercheMode_pmt === "VIR"
                        ? "radio-binary radio-binary-lg cheked"
                        : "radio-binary radio-binary-lg"
                    }
                    onClick={() => {
                      handleClick(clickedVIR, setclickedVIR);
                      setclickedCHE("");
                      setclickedESP("");
                      setclickedCB("");
                      setsearchMode_pmt("VIR");
                    }}
                  >
                    VIR
                  </button>
                  <button
                    type=""
                    className={
                      sercheMode_pmt === "CB"
                        ? "radio-binary radio-binary-lg cheked"
                        : "radio-binary radio-binary-lg"
                    }
                    onClick={() => {
                      handleClick(clickedCB, setclickedCB);
                      setclickedCHE("");
                      setclickedVIR("");
                      setclickedESP("");
                      setsearchMode_pmt("CB");
                    }}
                  >
                    CB
                  </button>
                </div>
              </div>
              <div className="champ-cherche-xsm">
                <label className="label-form">Année : </label>
                <input
                  type="number"
                  name="yearInput"
                  className="input-form mt-10"
                  defaultValue={new Date().getFullYear()}
                  onChange={(e) => setSearchAnnee(e.target.value)}
                  placeholder="2024"
                />
              </div>
              <div className="champ-cherche-xsm">
                <button type="submit" className="btn btn-sm ml-5">
                  Rechercher
                </button>
                <button
                  type="button"
                  className="btn btn-sm ml-5"
                  onClick={() => {
                    setActionCheck("");
                    setclickedAdidy("");
                    setclickedDon("");
                    setclickedSous("");
                    setclickedESP("");
                    setclickedVIR("");
                    setclickedCHE("");
                    setclickedCB("");
                    setSearchAnnee(new Date().getFullYear().toString());
                    setSelectedCotisationTyp([]);
                    setSelectedCotisationMt([]);
                    setSearchPerformed(false);
                    setSelecteddateUser("");
                    setsearchMode_pmt("");
                    setsearchTyp_pmt("");
                    setSelectedUser([]);
                    // Réinitialiser la sélection de l'utilisateur
                    const radioButtons = document.querySelectorAll(
                      'input[type="radio"][name="selectedUser"]'
                    );
                    radioButtons.forEach((button) => {
                      button.checked = false;
                    });
                  }}
                >
                  Réinitialiser
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="bar mt-30"></div>
        <div className="block-liste">
          <div className="liste-title">
            <p>LISTE DES COTISATIONS</p>
          </div>
          <div className="liste-containt">
            <div className="table">
              <div className="theader">
                <div className="check">
                  <div className="box"></div>
                </div>
                <div className="w-100">Matricule</div>
                <div className="w-200">Nom</div>
                <div className="w-200">Prénom</div>
                <div className="w-100">Montant</div>
                <div className="w-100">Type Cot.</div>
                <div className="w-100">Mode Pmt</div>
                <div className="w-100">Date Pmt</div>
                <div className="w-300">Notes</div>
              </div>
              <div className="tbody">
                {currentCotisation.map((user) => (
                  <div key={user.id} className="liste-value">
                    <div>
                      <input
                        className="check check-box"
                        type="radio"
                        name="selectedUser"
                        value={user.id}
                        onChange={(event) => handleInputChange(event)}
                      />
                    </div>
                    <div className="w-100">{user.id_mbr}</div>
                    <div className="w-200">{user.nom}</div>
                    <div className="w-200">{user.prenom}</div>
                    <div className="w-100">{user.mt}</div>
                    <div className="w-100">{user.typ_mt}</div>
                    <div className="w-100">{user.mod_pmt}</div>
                    <div className="w-100">
                      {format(new Date(user.dat_pmt), "dd-MM-yyyy")}
                    </div>
                    <div className="w-300">{user.notes}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="button-modif button-modif-xxl">
          <div className="button-modif-action">
            <button
              className={
                !actionCheck ? "display-none" : "btn-param revoque ml-5"
              }
              onClick={oneExporter}
            >
              Exporter
            </button>
            <button className="btn-param revoque ml-5" onClick={tousExporter}>
              Tout exporter
            </button>
          </div>
          <div className="pagination">
            <div className="right-page">
              {Array.from(
                {
                  length: Math.ceil(
                    filteredCotisation.length / cotisationPerPage
                  ),
                },
                (_, index) => {
                  const startPage = Math.max(currentPage - 2, 0);
                  const endPage = Math.min(
                    startPage + 3,
                    Math.ceil(filteredCotisation.length / cotisationPerPage)
                  );

                  if (index >= startPage && index < endPage) {
                    return (
                      <button
                        key={index}
                        onClick={() => paginate(index + 1)} // Appeler la fonction paginate avec le numéro de page correspondant
                        className={
                          currentPage === index + 1
                            ? "pageNum active"
                            : "pageNum"
                        }
                      >
                        {index + 1}
                      </button>
                    );
                  } else if (index === startPage - 1 && currentPage > 3) {
                    return <span key="ellipsis-start">...</span>;
                  } else if (
                    index === endPage &&
                    currentPage <
                      Math.ceil(filteredCotisation.length / cotisationPerPage) -
                        2
                  ) {
                    return <span key="ellipsis-end">...</span>;
                  }
                  return null;
                }
              )}
            </div>
          </div>
        </div>
        <p
          className={
            !actionCheck ? "display-none" : "subtitle text-center mt-10"
          }
        >
          Type :
          <span className="ml-5">
            {cotisationSelectedTyp === "S"
              ? "Souscription"
              : cotisationSelectedTyp === "A"
              ? "Adidy"
              : cotisationSelectedTyp === "D"
              ? "Don"
              : ""}
          </span>
          , Montant : <span className="ml-5">{cotisationSelectedMt}€</span> et
          Date de paiement : <span className="ml-5">{dateselectedUser}</span>
        </p>
      </>
    );
  }

  const renderForm = () => {
    switch (action) {
      case "error":
        return <ErrorPage />;
      case "data":
        return <DataPage />;
      default:
        return <ErrorPage />;
    }
  };

  return (
    <>
      <div className="container">
        <div className="container-lg">
          {" "}
          <div className="mt-30">{renderForm()}</div>
          <div className="bar mt-30"></div>
          <div className="mt-30 text-center">@ProjetAdidy</div>
        </div>
      </div>
    </>
  );
}

export default Cotisation_user;
