import { createBrowserRouter } from "react-router-dom";
import Login from "./Login/Login";
import SelectEmail from "./Login/SelectEmail";
import Parametrage from "./pages/Parametrage";
import NotFund from "./pages/NotFund";
import DefaultLayout from "./components/DefaultLayout";
import GuestLayout from "./components/GuestLayout";
import Accueil from "./pages/Accueil";
import Membre from "./pages/Membre";
import Cotisation from "./pages/Cotisation";
import Reporting from "./pages/Reporting";
import Users from "./pages/Users";
import UtilisateursPage from "./pages/Param_utilisateur";

const Router = createBrowserRouter([
  {
    path: "/",
    element: <DefaultLayout />,
    children: [
      {
        path: "/",
        element: <Accueil />,
      },
      {
        path: "/accueil",
        element: <Accueil />,
      },
      {
        path: "/membre",
        element: <Membre />,
      },
      {
        path: "/cotisation",
        element: <Cotisation />,
      },
      {
        path: "/reporting",
        element: <Reporting />,
      },
      {
        path: "/parametrage",
        element: <Parametrage />,
      },
      {
        path: "/users",
        element: <Users />,
      },
      {
        path: "/parametreUtilisateurs",
        element: <UtilisateursPage />,
      },
    ],
  },

  {
    path: "/",
    element: <GuestLayout />,
    children: [
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/selectEmail",
        element: <SelectEmail />,
      },
    ],
  },

  {
    path: "*",
    element: <NotFund />,
  },
  {
    path: "/notFund",
    element: <NotFund />,
  },
]);

export default Router;
