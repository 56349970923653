import React, { useEffect, useState } from "react";
import axios from "axios";
import MembreAdminPage from "./Membre_admin";
import MembreUserPage from "./Membre_user";

function Membre() {
  const [role, setRole] = useState();

  // Envoyer une requête GET pour récupérer l'utilisateurs connecté
  useEffect(() => {
    const show = async () => {
      await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/user`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`, //authorization token pour récupérer l'utilisateurs
          },
        })
        .then((response) => {
          setRole(response.data.user.profil); //récupéré profil utilisateurs
        })
        .catch((error) => {
          console.error(
            "Une erreur s'est produite lors du chargement des données:",
            error
          );
        });
    };

    show();
  }, []);

  const renderPage = () => {
    if (role === "ADM") {
      return <MembreAdminPage />;
    } else if(role === "USR") {
      return <MembreUserPage  />;
    }else {
      return '';
    }
  };

  return renderPage();
}

export default Membre;
